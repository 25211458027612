<svg
	xmlns="http://www.w3.org/2000/svg"
	viewBox="2 0 20 20"
	fill="none"
	stroke="currentColor"
	aria-hidden="true"
	stroke-width="2"
	stroke-linecap="round"
	stroke-linejoin="round"><polyline points="20 6 9 17 4 12" /></svg
>
